import React, { useEffect, useRef, useState } from 'react'
import { Map } from 'ol'
import { GeoJSON, WFS } from 'ol/format'
import { equalTo } from 'ol/format/filter'
import { Vector } from 'ol/source'
import { Vector as VectorLayer } from 'ol/layer'
import { Style, Stroke } from 'ol/style'
import { useLocation } from 'react-router'
import { baseMapGroup, startingView, GEOSERVER_MAIN_URL, GEOSERVER_WORKSPACE, GEOSERVER_NS } from './common'

const DEFAULT_LAYERS = process.env.REACT_APP_DEFAULT_GEO_LAYERS.split(',')

export default function MainMap() {
    const [mainMap, setMap] = useState()
 
    const mapEl = useRef()
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const globalId = searchParams.get('globalid')
    const layer = searchParams.get('layer')

    useEffect(() => {
        const initialMap = new Map({
            target: mapEl.current,
            layers: [
                baseMapGroup,
            ],
            view: startingView,
            controls: []
        })

        setMap(initialMap)
    }, [])

    useEffect(() => {
        const updateLayer = async () => {
            const pinpointVectorSource = new Vector()
            const pinpointVectorLayer = new VectorLayer({
                source: pinpointVectorSource,
                style: new Style({
                    stroke: new Stroke({
                        color: '#FF0000',
                        width: 3,
                    })
                })
            })
            mainMap.addLayer(pinpointVectorLayer)

            const featureRequest = new WFS().writeGetFeature({
                srsName: 'EPSG:900913',
                featureNS: GEOSERVER_NS,
                featurePrefix: GEOSERVER_WORKSPACE,
                featureTypes: layer ? [layer] : DEFAULT_LAYERS,
                outputFormat: 'application/json',
                filter: equalTo('GlobalID', globalId),
            })
            console.log(`URL >>> ${GEOSERVER_MAIN_URL}`)
            fetch(`${GEOSERVER_MAIN_URL}/wfs`, {
                method: 'POST',
                body: new XMLSerializer().serializeToString(featureRequest)
            }).then((response) => {
                return response.json()
            }).then((json) => {
                const features = new GeoJSON().readFeatures(json)
                pinpointVectorSource.addFeatures(features)
                mainMap.getView().fit(pinpointVectorSource.getExtent())
            }).catch((err) => {
                console.log(err)
            })
        }

        if (mainMap) {
            updateLayer()
        }
    }, [globalId, layer, mainMap])

    return (
        <>
            <div ref={mapEl} className="map-container"></div>
        </>
    )
}