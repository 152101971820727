import { View } from 'ol'
import { Group, Tile, Vector } from 'ol/layer'
import { OSM, WMTS, Vector as VectorSource } from 'ol/source'
import { get as olget } from 'ol/proj'
import { getWidth, getTopLeft } from 'ol/extent'
import WMTSTileGrid from 'ol/tilegrid/WMTS'
import { Style, Fill } from 'ol/style'
import { GeoJSON } from 'ol/format'
import { bbox } from 'ol/loadingstrategy'

export const GEOSERVER_MAIN_URL = process.env.REACT_APP_GEOSERVER_MAIN_URL
export const GEOSERVER_WORKSPACE = process.env.REACT_APP_GEOSERVER_WORKSPACE
export const GEOSERVER_NS = process.env.REACT_APP_GEOSERVER_NS

export const startingView = new View({
    center: [-240000, 6960000],
    projection: 'EPSG:900913',
    zoom: 10,
    maxZoom: 16,
    constrainResolution: true,
})

export const baseMapGroup = new Group({
    name: 'Basemap layer group',
    layers: [
        new Tile({
            title: 'Open Street Map',
            name: 'osmBasemap',
            opacity: 0.7,
            type: 'base',
            visible: true,
            source: new OSM()
        })
    ]
})

const projection = olget('EPSG:900913');
const tileSizePixels = 256;
const projectionExtent = projection.getExtent();
const size = getWidth(projectionExtent) / tileSizePixels;
const resolutions = [];
const matrixIds = [];
for (let i = 0; i < 21; ++i) {
    // generate resolutions and matrixIds arrays for WMTS
    resolutions[i] = size / Math.pow(2, i);
    matrixIds[i] = 'EPSG:900913:' + i;
}

export const wmtsGroup = new Group({
    name: 'WMTS layer group',
    layers: [
        new Tile({
            name: `${GEOSERVER_WORKSPACE}:Land_Acquisition_Parcels`,
            title: 'Land Acquisition Parcels',
            wrapX: true,
            visible: false,
            type: 'wmts',
            source: new WMTS({
                url: `${GEOSERVER_MAIN_URL}/gwc/service/wmts/`,
                layer: `${GEOSERVER_WORKSPACE}:Land_Acquisition_Parcels`,
                matrixSet: 'EPSG:900913',
                format: 'image/png',
                projection: projection,
                tileGrid: new WMTSTileGrid({
                    origin: getTopLeft(projectionExtent),
                    resolutions: resolutions,
                    matrixIds: matrixIds,
                }),
            }),
        }),
        new Tile({
            name: `${GEOSERVER_WORKSPACE}:Land_Acquisition_Areas`,
            title: 'Work Packages',
            wrapX: true,
            visible: false,
            type: 'wmts',
            source: new WMTS({
                url: `${GEOSERVER_MAIN_URL}/gwc/service/wmts/`,
                layer: `${GEOSERVER_WORKSPACE}:Land_Acquisition_Areas`,
                matrixSet: 'EPSG:900913',
                format: 'image/png',
                projection: projection,
                tileGrid: new WMTSTileGrid({
                    origin: getTopLeft(projectionExtent),
                    resolutions: resolutions,
                    matrixIds: matrixIds,
                }),
            }),
        })
    ]
})

const wfsStyle = new Style({
    fill: new Fill({
        color: 'rgba(255,0,0,0)',
    }),
})

export const wfsGroup = new Group({
    name: 'WFS layer group',
    layers: [
        new Vector({
            name: `${GEOSERVER_WORKSPACE}:Land_Acquisition_Parcels`,
            title: 'Land Acquisition Parcels',
            style: wfsStyle,
            visible: true,
            minZoom: 14,
            // maxZoom: 16,
            source: new VectorSource({
                name: `${GEOSERVER_WORKSPACE}:Land_Acquisition_Parcels`,
                title: 'Land Acquisition Parcels',
                format: new GeoJSON(),
                url: (extent) => {
                    return (
                        `${GEOSERVER_MAIN_URL}/${GEOSERVER_WORKSPACE}/ows?service=WFS&` +
                        `version=2.0.0&request=GetFeature&typeName=${GEOSERVER_WORKSPACE}:Land_Acquisition_Parcels&` +
                        'outputFormat=application/json&srsname=EPSG:900913&' +
                        'bbox=' +
                        extent.join(',') +
                        ',EPSG:900913'
                    );
                },
                strategy: bbox,
            }),
        }),
        new Vector({
            name: `${GEOSERVER_WORKSPACE}:Land_Acquisition_Areas`,
            title: 'Work Packages',
            style: wfsStyle,
            visible: true,
            minZoom: 14,
            // maxZoom: 16,
            source: new VectorSource({
                name: `${GEOSERVER_WORKSPACE}:Land_Acquisition_Areas`,
                title: 'Work Packages',
                format: new GeoJSON(),
                url: (extent) => {
                    return (
                        `${GEOSERVER_MAIN_URL}/${GEOSERVER_WORKSPACE}/ows?service=WFS&` +
                        `version=2.0.0&request=GetFeature&typeName=${GEOSERVER_WORKSPACE}:Land_Acquisition_Areas&` +
                        'outputFormat=application/json&srsname=EPSG:900913&' +
                        'bbox=' +
                        extent.join(',') +
                        ',EPSG:900913'
                    );
                },
                strategy: bbox,
            }),
        })
    ]
})