import * as React from 'react' 
import { Route, Switch, Link } from 'react-router-dom'
import MainMap from './map/map'

import './styles.css'

export default function App() {
    return (
        <main>
            <Switch>
                <Route path="/" component={Home} exact></Route>
                <Route path="/map" component={MainMap}></Route>
                <Route component={NotFound} />
            </Switch>
        </main>
    )
}

function Home() {
    const [testId, setTestId] = React.useState('d8c230c7-fb84-444e-9151-7803d5f1655c')
    const [testLayer, setTestLayer] = React.useState('')
    const mapLink = `/map/?globalid=${testId}`
    const mapLayerLink = `/map/?globalid=${testId}&layer=${testLayer ? testLayer : 'Land_Acquisition_Plots'}`
    const mapApiLink = `api/maps/screenshot/?globalid=${testId}${testLayer ? `&layer=${testLayer}` : ''}`

    const onIdInput = (evt) => {
        setTestId(evt.target.value)
    }

    const onLayerInput = (evt) => {
        setTestLayer(evt.target.value)
    }

    return (
        <div>
            <h1>GIS Web Service</h1>
            <form>
                <div>
                    <label for="globalId">Global Id: </label>
                    <input type="text" id="globalId" name="globalId" onChange={onIdInput}></input>
                </div>
                <div>
                    <label for="globalId">Layer (optional): </label>
                    <input type="text" id="layer" name="layer" onChange={onLayerInput}></input>
                </div>
            </form>
            <table>
                <tr>
                    <th>Description</th>
                    <th>Link</th>
                </tr>
                <tr>
                    <td>Show map with given globalId on predefined layers</td>
                    <td><Link to={mapLink}>{mapLink}</Link></td>
                </tr>
                <tr>
                    <td>Show map with given globalId on specific layer</td>
                    <td><Link to={mapLayerLink}>{mapLayerLink}</Link></td>
                </tr>
                <tr>
                    <td>Run map screenshot API with given globalId and/or layer</td>
                    <td><a href={`${window.location.href}${mapApiLink}`}>{mapApiLink}</a></td>
                </tr>
            </table>
        </div>
    )
}

function NotFound() {
    return (
        <div>
            <h1>GIS Web Service</h1>
            <h2>Page not found!</h2>
        </div>
    )
}